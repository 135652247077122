<template>
  <div class="pdf-viewer">
    <!-- <pdf src="/static/ppp.pdf"></pdf> -->
    <iframe :src="pdfSrc" width="100%" align="middle"></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pdfSrc: "",
    };
  },
  components: {},
  computed: {},
  mounted() {
    // this.pdfSrc = `http://tot.xlteacher.cn${this.$route.query.pu}`;
    this.pdfSrc = `http://tot.xlteacher.cn/static/pdfjs/web/viewer.html?file=${this.$route.query.pu}`;
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.pdf-viewer {
  min-height: calc(100vh - 80px);
  overflow: hidden;
  max-width: 1200px;
  margin: 20px auto;
  iframe {
    border: 0;
    min-height: calc(100vh - 90px);
  }
}
</style>
